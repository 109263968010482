export default [
  {
    id: "fullName",
    name: "fullName",
    type: "text",
    label: "Name",
    placeholder: "Name",
    required: true,
  },
  {
    id: "title",
    name: "title",
    type: "text",
    label: "Title",
    placeholder: "Title",
    required: true,
  },
  {
    id: "phone",
    name: "phone",
    type: "tel",
    label: "Phone",
    placeholder: "Phone",
    pattern: "[0-9]{3}-[0-9]{3}-[0-9]{4}",
    required: true,
  },
  {
    id: "email",
    name: "email",
    type: "email",
    label: "Email",
    placeholder: "Email",
    required: true,
  },
  {
    id: "companyName",
    name: "companyName",
    type: "text",
    label: "Company Name",
    placeholder: "Company Name",
    required: true,
  },
  {
    id: "url",
    name: "url",
    type: "url",
    label: "Company Website",
    placeholder: "https://example.com",
    required: false,
  },
]
