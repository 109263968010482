export default [
  {
    id: "oneMonth",
    name: "timeline",
    value: "1 Month",
    label: "1 Month",
    required: false,
  },
  {
    id: "twoThreeMonths",
    name: "timeline",
    value: "2-3 Months",
    label: "2-3 Months",
    required: false,
  },
  {
    id: "fourSixMonths",
    name: "timeline",
    value: "4-6 Months",
    label: "4-6 Months",
    required: false,
  },
  {
    id: "sevenTwelveMonths",
    name: "timeline",
    value: "7-12 Months",
    label: "7-12 Months",
    required: false,
  },
  {
    id: "year",
    name: "timeline",
    value: "1 year + ongoing",
    label: "1 year + ongoing",
    required: false,
  },
]
