export default [
  {
    id: "launching",
    name: "challenge",
    value: "Launching a new brand, product or service",
    label: "Launching a new brand, product or service",
    required: false,
  },
  {
    id: "evolution",
    name: "challenge",
    value: "New evolution of brand and company",
    label: "New evolution of brand and company",
    required: false,
  },
  {
    id: "betterWebsite",
    name: "challenge",
    value: "Need better website to run business around",
    label: "Need better website to run business around",
    required: false,
  },
  {
    id: "competition",
    name: "challenge",
    value: "Stand out from the competition",
    label: "Stand out from the competition",
    required: false,
  },
  {
    id: "seriously",
    name: "challenge",
    value: "Look more credible and be taken seriously",
    label: "Look more credible and be taken seriously",
    required: false,
  },
  {
    id: "brandStory",
    name: "challenge",
    value: "Improve our brand story and perception",
    label: "Improve our brand story and perception",
    required: false,
  },
  {
    id: "plan",
    name: "challenge",
    value: "Need a plan for who and where to reach customers",
    label: "Need a plan for who and where to reach customers",
    required: false,
  },
  {
    id: "perceptions",
    name: "challenge",
    value: "Changing perceptions",
    label: "Changing perceptions",
    required: false,
  },
  {
    id: "collaborator",
    name: "challenge",
    value: "Need a collaborator",
    label: "Need a collaborator",
    required: false,
  },
  {
    id: "other",
    name: "challenge",
    value: "Other",
    label: "Other",
    required: false,
  },
]
