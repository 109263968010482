export default [
  {
    id: "graphicDesign",
    value: "Graphic design of all kinds",
    label: "Graphic design of all kinds",
    required: false,
  },
  {
    id: "branding",
    value: "Branding",
    label: "Branding",
    required: false,
  },
  {
    id: "websiteDev",
    value: "Website development",
    label: "Website development",
    required: false,
  },
  {
    id: "marketing",
    value: "Marketing Strategy and Activation",
    label: "Marketing Strategy and Activation",
    required: false,
  },
  {
    id: "advertising",
    value: "Advertising(online, offline)",
    label: "Advertising(online, offline)",
    required: false,
  },
  {
    id: "socialMedia",
    value: "Social Media(content, management, design)",
    label: "Social Media(content, management, design)",
    required: false,
  },
  {
    id: "workshopsSpeaking",
    value: "Workshops, speaking",
    label: "Workshops, speaking",
    required: false,
  },
]
