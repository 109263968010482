/* eslint-disable */
import React from "react"
import styled from "styled-components"

import { fontSizer, colors } from "../../../Utilities"

const InputFieldStyled = styled.div`
  position: relative;
  width: 100%;
  padding-top: 3rem;

  @media (min-width: 768px) {
    width: calc(50% - 1.5rem);
  }

  label#input-fields-label {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    display: block;
    width: 100%;
    color: ${colors.colorSecondary};
  }

  input {
    width: 100%;
    padding: 1rem 2rem;
    border: 0.2rem solid ${colors.colorSecondary};
    background-color: ${colors.white};

    &::placeholder {
      color: ${colors.colorSecondary};
    }
  }

  #field-error-message {
    ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
    position: absolute;
    top: 1rem;
    left: 0rem;
    color: red !important;
    z-index: 10;
  }
`

const InputField = ({
  id,
  name,
  type,
  label,
  placeholder,
  required,
  value,
  onChangeFields,
  errors,
}) => {
  const errorMessageData = errors.find(error => {
    if (error.idref === name) {
      return error
    }
  })

  const errorMessage = errorMessageData ? (
    <p id="field-error-message" key={errorMessageData.idref}>
      {errorMessageData.message}
    </p>
  ) : (
    false
  )

  return (
    <InputFieldStyled>
      <label id="input-fields-label" htmlFor={id}>
        {label}
        {required && <span> ( * Required field * )</span>}
      </label>
      {errorMessage}

      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChangeFields}
      />
    </InputFieldStyled>
  )
}

export default InputField
