/* eslint-disable */
import React, { Component } from "react"
import styled from "styled-components"
import axios from "axios"
import Slider from "rc-slider"
import "rc-slider/assets/index.css"

import Checkbox from "./Checkbox"
import RadioButton from "./RadioButton"
import InputField from "./InputField"
import TextArea from "./TextArea"

import HelpWithData from "./FormData/HelpWithData"
import ChallengeData from "./FormData/ChallengeData"
import YourselfData from "./FormData/YourselfData"
import TimeLineData from "./FormData/TimeLineData"

import ErrorWarning from "./ErrorWarning"
import SubmittingWarning from "./SubmittingWarning"
import SuccessMessage from "./SuccessMessage"

import {
  standardWrapper,
  bodyCopy,
  colors,
  fontSizer,
  buttonOne,
  headlineTwo,
} from "../../../Utilities"

const MainFormSection = styled.section`
  .form-wrapper {
    ${standardWrapper};
    padding: 0;

    @media (min-width: 768px) {
      padding: 2rem;
    }
  }

  .main-title-main-form {
    width: 100%;

    h2 {
      ${headlineTwo};
    }
  }

  .main-form {
    width: 100%;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.1);

    @media (min-width: 768px) {
      padding: 2rem 4rem;
    }

    @media (min-width: 1025px) {
      max-width: 90rem;
      margin-right: auto;
      margin-left: 0;
    }

    label,
    p {
      ${bodyCopy};
      ${fontSizer(1.8, 2, 76.8, 150, 2)};
      color: #585858;
    }

    &__helpwith {
      position: relative;
      margin-bottom: 5rem;

      &--title {
        margin-bottom: 1.5rem;

        p {
          margin: 0;

          span {
            font-size: 0.75em;
          }
        }

        #field-error-message {
          ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
          position: absolute;
          top: 2.5rem;
          left: 0rem;
          color: red !important;
          z-index: 10;
        }
      }
    }

    &__challenge {
      position: relative;

      &--title {
        margin-bottom: 1.5rem;

        p {
          margin: 0;

          span {
            font-size: 0.75em;
          }
        }

        #field-error-message {
          ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
          position: absolute;
          top: 2.5rem;
          left: 0rem;
          color: red !important;
          z-index: 10;
        }
      }
    }

    &__yourself {
      margin: 5rem auto;

      &--title {
        width: 100%;
        margin-bottom: 1rem;

        p {
          margin: 0;
        }
      }

      &--fields {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;

        .form-textarea {
          width: 100%;
        }
      }
    }

    &__timeline {
      position: relative;
      &--title {
        margin-bottom: 1.5rem;

        p {
          margin: 0;

          span {
            font-size: 0.75em;
          }
        }

        #field-error-message {
          ${fontSizer(1.2, 1.4, 76.8, 150, 2)};
          position: absolute;
          top: 2.5rem;
          left: 0rem;
          color: red !important;
          z-index: 10;
        }
      }
    }

    &__budget {
      margin-bottom: 5rem;

      @media (min-width: 768px) {
        margin-bottom: 7.5rem;
      }

      @media (min-width: 1025px) {
        margin-bottom: 10rem;
      }

      p {
        font-weight: 300;
      }

      .rc-slider {
        width: calc(100% - 1.5rem);
        margin-right: 1rem;
        margin-left: 0.5rem;

        @media (min-width: 768px) {
          width: calc(100% - 6rem);
          margin-right: 3rem;
          margin-left: 3rem;
        }

        .rc-slider-rail {
          background-color: ${colors.colorPrimary};
          opacity: 0.5;
        }

        .rc-slider-track {
          background-color: ${colors.colorSecondary};
          opacity: 1;
        }

        .rc-slider-step {
          .rc-slider-dot {
            border-color: ${colors.colorPrimary};
          }

          .rc-slider-dot.rc-slider-dot-active {
            border-color: ${colors.colorSecondary};
          }
        }

        .rc-slider-handle {
          width: 2.5rem;
          height: 2.5rem;
          margin-top: -1.1rem;
          margin-left: -1.25rem;
          border-color: ${colors.colorSecondary};
        }

        .rc-slider-mark {
          .rc-slider-mark-text {
            ${bodyCopy};
            ${fontSizer(1.6, 1.6, 76.8, 150, 1.2)};
            color: ${colors.colorPrimary};
            opacity: 0.9;
          }
          .rc-slider-mark-text.rc-slider-mark-text-active {
            ${fontSizer(1.6, 1.6, 76.8, 150, 1.4)};
            color: ${colors.colorSecondary};
            font-weight: 700;
            opacity: 1;
          }
        }
      }
    }

    &__button {
      margin-top: 3rem;
      margin-bottom: 3rem;
      padding-top: 3rem;
      border-top: solid 0.1rem ${colors.sliver};

      button {
        ${buttonOne};
      }
    }
  }
`

class MainForm extends Component {
  constructor(props) {
    super(props)
    this.changeTheBudget = this.changeTheBudget.bind(this)
    this.changeNeedHelp = this.changeNeedHelp.bind(this)
    this.onChangeFields = this.onChangeFields.bind(this)
    this.submitTheForm = this.submitTheForm.bind(this)
    this.submitTheDataToWebsite = this.submitTheDataToWebsite.bind(this)
    this.emailSentSuccess = this.emailSentSuccess.bind(this)
    this.formHasErrors = this.formHasErrors.bind(this)
    this.fixTheFormErrors = this.fixTheFormErrors.bind(this)
    this.successAndClearForm = this.successAndClearForm.bind(this)

    this.state = {
      needHelp: [],
      budget: "$1,500",
      budgetData: 0,
      challenge: "",
      fullName: "",
      title: "",
      phone: "",
      email: "",
      companyName: "",
      url: "",
      project: "",
      timeline: "",
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    }
  }

  onChangeFields(e) {
    this.setState({ [e.target.name]: e.target.value })
  }

  changeNeedHelp(event) {
    const helpValue = event.target.value
    const currentHelp = [...this.state.needHelp]
    const isAlreadyChecked = currentHelp.indexOf(helpValue)

    if (isAlreadyChecked === -1) {
      this.setState(prevState => {
        return {
          ...prevState,
          needHelp: [...this.state.needHelp, helpValue],
        }
      })
    } else {
      currentHelp.splice(isAlreadyChecked, 1)
      this.setState(prevState => {
        return {
          ...prevState,
          needHelp: currentHelp,
        }
      })
    }
  }

  changeTheBudget(value) {
    let budget = "$1,500"
    if (value === 0) budget = "$1,500"
    if (value === 25) budget = "$5,000"
    if (value === 50) budget = "$10,000"
    if (value === 75) budget = "$15,000"
    if (value === 100) budget = "$20,000+"

    this.setState(prevState => {
      return {
        ...prevState,
        budget: budget,
        budgetData: value,
      }
    })
  }

  submitTheForm(e) {
    e.preventDefault()

    this.setState(prevState => {
      return {
        ...prevState,
        submitting: true,
        errors: [],
      }
    })

    const bodyFormData = new FormData()
    const fieldErrors = []

    if (this.state.needHelp.length > 0) {
      bodyFormData.append("needHelp", this.state.needHelp)
    } else {
      fieldErrors.push({
        idref: "needHelp",
        message: "You need to select at least on option",
      })
    }

    if (this.state.budget !== "") {
      bodyFormData.append("budget", this.state.budget)
    } else {
      fieldErrors.push({
        idref: "budget",
        message: "You need to select a budget option",
      })
    }

    if (this.state.challenge !== "") {
      bodyFormData.append("challenge", this.state.challenge)
    } else {
      fieldErrors.push({
        idref: "challenge",
        message: "You need to select a challege option.",
      })
    }

    if (this.state.fullName !== "") {
      bodyFormData.append("fullName", this.state.fullName)
    } else {
      fieldErrors.push({
        idref: "fullName",
        message: "You need to fill out your name.",
      })
    }

    if (this.state.title !== "") {
      bodyFormData.append("title", this.state.title)
    } else {
      fieldErrors.push({
        idref: "title",
        message: "You need to fill out your title.",
      })
    }

    if (this.state.phone !== "") {
      bodyFormData.append("phone", this.state.phone)
    } else {
      fieldErrors.push({
        idref: "phone",
        message: "You need to fill out your phone number.",
      })
    }

    if (this.state.email !== "") {
      bodyFormData.append("email", this.state.email)
    } else {
      fieldErrors.push({
        idref: "email",
        message: "You need to fill out your amail address.",
      })
    }

    if (this.state.companyName !== "") {
      bodyFormData.append("companyName", this.state.companyName)
    } else {
      fieldErrors.push({
        idref: "companyName",
        message: "You need to fill out your company name.",
      })
    }

    if (this.state.url !== "") bodyFormData.append("url", this.state.url)

    if (this.state.project !== "") {
      bodyFormData.append("project", this.state.project)
    } else {
      fieldErrors.push({
        idref: "project",
        message: "You need a brief description of your project.",
      })
    }
    if (this.state.timeline !== "") {
      bodyFormData.append("timeline", this.state.timeline)
    } else {
      fieldErrors.push({
        idref: "timeline",
        message: "You need to select a timeline option.",
      })
    }

    setTimeout(() => {
      if (fieldErrors.length > 0) {
        this.setState(prevState => {
          return {
            ...prevState,
            errorWarnDisplay: true,
            submitting: false,
            errors: [...fieldErrors],
          }
        })
        return
      }

      // clear to send to server....
      this.submitTheDataToWebsite(bodyFormData)
    }, 2000)
  }

  async submitTheDataToWebsite(data) {
    const FORM_POST_URL = `https://switchbackcreative.swbdatabases.ca/wp-json/contact-form-7/v1/contact-forms/4332/feedback`
    const config = { headers: { "Content-Type": "multipart/form-data" } }
    const serverResponse = await axios.post(FORM_POST_URL, data, config)

    if (serverResponse.data.status === "mail_sent") {
      this.emailSentSuccess(serverResponse)
    } else {
      this.formHasErrors(serverResponse.data.invalidFields)
    }
  }

  fixTheFormErrors() {
    this.setState(prevState => {
      return {
        ...prevState,
        errorWarnDisplay: false,
        submitting: false,
      }
    })
  }

  emailSentSuccess(res) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        success: true,
      }
    })
  }

  formHasErrors(errorsFields) {
    this.setState(prevState => {
      return {
        ...prevState,
        submitting: false,
        errors: errorsFields,
      }
    })
  }

  successAndClearForm() {
    this.setState(prevState => {
      return {
        ...prevState,
        needHelp: [],
        budget: "$1,500",
        budgetData: 0,
        challenge: "",
        fullName: "",
        title: "",
        phone: "",
        email: "",
        companyName: "",
        url: "",
        project: "",
        timeline: "",
        submitting: false,
        errorWarnDisplay: false,
        success: false,
        errors: [],
      }
    })
  }

  render() {
    const marks = {
      "0": "$1,500",
      "25": "$5,000",
      "50": "$10,000",
      "75": "$15,000",
      "100": "$20,000+",
    }

    const helpErrors = this.state.errors.find(error => {
      if (error.idref === "needHelp") {
        return error
      }
    })

    const helpErrorMessage = helpErrors ? (
      <p id="field-error-message" key={helpErrors.idref}>
        {helpErrors.message}
      </p>
    ) : (
      false
    )

    const challengeErrors = this.state.errors.find(error => {
      if (error.idref === "challenge") {
        return error
      }
    })

    const challengeErrorMessage = challengeErrors ? (
      <p id="field-error-message" key={challengeErrors.idref}>
        {challengeErrors.message}
      </p>
    ) : (
      false
    )

    const timelineErrors = this.state.errors.find(error => {
      if (error.idref === "timeline") {
        return error
      }
    })

    const timelineErrorMessage = timelineErrors ? (
      <p id="field-error-message" key={timelineErrors.idref}>
        {timelineErrors.message}
      </p>
    ) : (
      false
    )

    return (
      <MainFormSection>
        <div className="form-wrapper">
          <form onSubmit={this.submitTheForm} className="main-form">
            <div className="main-title-main-form">
              <h2>Contact Switchback Creative Today</h2>
            </div>
            <div className="main-form__helpwith">
              <div className="main-form__helpwith--title">
                <p>
                  I need help with... (select as many as apply)
                  <span> ( * Required field * )</span>
                </p>
                {helpErrorMessage}
              </div>
              {HelpWithData.map((field, index) => {
                return (
                  <Checkbox
                    id={field.id}
                    value={field.value}
                    label={field.label}
                    required={field.required}
                    key={index}
                    changeNeedHelp={this.changeNeedHelp}
                    errors={this.state.errors}
                  />
                )
              })}
            </div>
            <div className="main-form__challenge">
              <div className="main-form__challenge--title">
                <p>
                  What is the biggest challenge you are currently facing?
                  <span> ( * Required field * )</span>
                </p>
                {challengeErrorMessage}
              </div>
              {ChallengeData.map((field, index) => {
                return (
                  <RadioButton
                    id={field.id}
                    name={field.name}
                    value={field.value}
                    label={field.label}
                    required={field.required}
                    key={index}
                    onChangeFields={this.onChangeFields}
                    boxChecked={this.state.challenge}
                    errors={this.state.errors}
                  />
                )
              })}
            </div>
            <div className="main-form__yourself">
              <div className="main-form__yourself--title">
                <p>Tell us about yourself.</p>
              </div>
              <div className="main-form__yourself--fields">
                {YourselfData.map((field, index) => {
                  return (
                    <InputField
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                      placeholder={field.placeholder}
                      required={field.required}
                      key={index}
                      value={this.state[field.name]}
                      onChangeFields={this.onChangeFields}
                      errors={this.state.errors}
                    />
                  )
                })}
                <TextArea
                  id="project"
                  name="project"
                  label="Describe your project"
                  placeholder="Describe your project"
                  required={true}
                  value={this.state.project}
                  onChangeFields={this.onChangeFields}
                  errors={this.state.errors}
                />
              </div>
            </div>

            <div className="main-form__budget">
              <p>
                <span>What is your budget? </span>
                This helps us guide you down the right path. We know talking
                about money can be scary but we pride ourselves on doing the
                best work possible within the constraints around us, so be brave
                and share your budget considerations honestly.
              </p>
              <Slider
                min={0}
                marks={marks}
                step={null}
                defaultValue={0}
                value={this.state.budgetData}
                onChange={this.changeTheBudget}
              />
            </div>

            <div className="main-form__timeline">
              <div className="main-form__timeline--title">
                <p>
                  What is your timeline?<span> ( * Required field * )</span>
                </p>

                {timelineErrorMessage}
              </div>
              {TimeLineData.map((field, index) => {
                return (
                  <RadioButton
                    id={field.id}
                    name={field.name}
                    value={field.value}
                    label={field.label}
                    required={field.required}
                    key={index}
                    onChangeFields={this.onChangeFields}
                    boxChecked={this.state.timeline}
                    errors={this.state.errors}
                  />
                )
              })}
            </div>
            <div className="main-form__button">
              <p>
                We aim to get back to our new found friends within a couple of
                business days.
              </p>
              <button>Let's do this</button>
            </div>
          </form>
        </div>
        {this.state.submitting && <SubmittingWarning />}
        {this.state.errorWarnDisplay && (
          <ErrorWarning fixTheFormErrors={this.fixTheFormErrors} />
        )}
        {this.state.success && (
          <SuccessMessage successAndClearForm={this.successAndClearForm} />
        )}
      </MainFormSection>
    )
  }
}

export default MainForm
