import React from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import * as error from "./FormAnimations/error.json"
import { buttonTwo, colors } from "../../../Utilities"

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: error.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const ErrorWarningStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorPrimary};
  z-index: 999999999;

  .warning-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .warning-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonTwo};
    }
  }
`

const ErrorWarning = props => {
  return (
    <ErrorWarningStyled>
      <div className="warning-wrapper">
        <div className="warning-content">
          <p>Your form has some errors or feilds missing.</p>
          <Lottie options={defaultOptions} height={200} width={200} />
          <p>Please fix and try to resend again.</p>
          <button onClick={props.fixTheFormErrors}>Close</button>
        </div>
      </div>
    </ErrorWarningStyled>
  )
}

export default ErrorWarning
