import React from "react"
import styled from "styled-components"
import Lottie from "react-lottie"
import * as success from "./FormAnimations/success.json"

import { buttonTwo, colors } from "../../../Utilities"

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: success.default,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

const SuccessMessageStyled = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.colorSecondary};
  z-index: 999999999;

  .submit-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .submit-content {
    align-self: center;
    max-width: 50rem;
    padding: 3rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align: center;

    button {
      ${buttonTwo};
    }
  }
`

const SuccessMessage = props => {
  return (
    <SuccessMessageStyled>
      <div className="submit-wrapper">
        <div className="submit-content">
          <p>Your form has successfully been submitted!</p>
          <p>Thank you and we will be in touch very soon!</p>
          <Lottie options={defaultOptions} height={200} width={200} />
          <button onClick={props.successAndClearForm}>Done</button>
        </div>
      </div>
    </SuccessMessageStyled>
  )
}

export default SuccessMessage
