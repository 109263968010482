import React from "react"
import styled from "styled-components"

import { headlineOne, headlineTwo } from "../../../Utilities"
import StandardWrapper from "../../Shared/StandardWrapper"
import OfficeScene from "../../../Elements/Images/OfficeScene"

const HeaderSceneSection = styled.section`
  margin: 0 auto 10rem;
  padding: 25rem 0 0;
  background: linear-gradient(
    to top,
    #fff 0%,
    #fff 5rem,
    #efefef 5rem,
    #efefef 100%
  );

  @media (min-width: 768px) {
    padding: 35rem 0 0;
  }

  @media (min-width: 1025px) {
    padding: 25rem 0 0;
  }

  .connect-title {
    width: 100%;
    text-align: center;

    h2 {
      ${headlineOne};
      color: #707070;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      ${headlineTwo};
      color: #707070;
      text-align: center;
    }
  }

  .office-scene {
    width: 100%;

    @media (min-width: 1025px) {
      max-width: 85rem;
      margin: 0 auto;
    }
  }
`

const HeaderScene = () => {
  return (
    <HeaderSceneSection>
      <StandardWrapper>
        <div className="connect-title">
          <h2>Connect</h2>
          <p>Start a project. Let's ride.</p>
        </div>
        <div className="office-scene">
          <OfficeScene />
        </div>
      </StandardWrapper>
    </HeaderSceneSection>
  )
}

export default HeaderScene
