import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { standardWrapper, buttonTwo, bodyCopy } from "../../../Utilities"

const IntroSection = styled.section`
  margin-bottom: 2.5rem;
  .intro-wrapper {
    ${standardWrapper};
  }

  .intro-content {
    width: 100%;

    p {
      ${bodyCopy};
    }
  }

  .intro-link {
    width: 100%;

    a {
      ${buttonTwo};
    }
  }
`

const Intro = () => {
  return (
    <IntroSection>
      <div className="intro-wrapper">
        <div className="intro-content">
          <p>
            We work with the best fit clients and are always on the lookout for
            our next collaboration. If you are looking for an estimate or more
            information fill out the form below. If you are a non-profit
            organization looking for our TrailBlazer Program learn more here.{" "}
          </p>
        </div>
        <div className="intro-link">
          <Link to="/trailblazer-fund/">Trailblazer</Link>
        </div>
      </div>
    </IntroSection>
  )
}

export default Intro
