import React from "react"

import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import OfficeScene from "../components/PageParts/Contact/HeaderScene"
import Intro from "../components/PageParts/Contact/Intro"
import MainForm from "../components/PageParts/Contact/MainForm"

const ContactPage = props => (
  <Layout location={props.location.pathname}>
    <SEO
      title="Switchback Creative - Contact Us and Let's Tell Your Story"
      description="We create memorable and instantly recognizable brands that tell a story + create a following for your business. Contact us and let's start working together."
      location={props.location.pathname}
    />
    <OfficeScene />
    <Intro />
    <MainForm />
  </Layout>
)

export default ContactPage
