import React from "react"
import styled from "styled-components"

import { colors } from "../../../Utilities"

const RadioButtonStyled = styled.div`
  margin-left: 1.5rem;

  input[type="radio"] {
    opacity: 0;
  }
  label {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-left: 3rem;
    line-height: 1;

    @media (min-width: 768px) {
      display: inline;
      margin-left: 0;
      padding-left: 2rem;
    }
  }

  label::before,
  label::after {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    content: "";
  }

  label::before {
    display: block;
    position: absolute;
    top: 0.2rem;
    left: -4rem;
    height: 2.2rem;
    width: 2.2rem;
    border: 0.2rem solid ${colors.colorSecondary};
    content: "";
  }

  @media (min-width: 768px) {
    label::before {
      left: -2.5rem;
    }
  }

  label::after {
    display: block;
    position: absolute;
    top: 0rem;
    left: -3.6rem;
    width: 1.25rem;
    height: 0.75rem;
    transform: rotate(-45deg);
    border-left: 0.3rem solid ${colors.colorAccent};
    border-bottom: 0.3rem solid ${colors.colorAccent};
    content: "";
  }

  @media (min-width: 768px) {
    label::after {
      left: -2rem;
    }
  }

  input[type="radio"] + label::after {
    content: none;
  }

  input[type="radio"]:focus + label::before {
    outline: ${colors.colorTertiary} auto 0.2rem;
  }

  input[type="radio"]:checked + label::before {
    border: 0.2rem solid ${colors.colorAccent};
  }

  input[type="radio"]:checked + label::after {
    content: "";
  }

  input[type="radio"]:checked + label {
    color: ${colors.colorAccent};
  }
`

const RadioButton = ({
  id,
  name,
  value,
  label,
  required,
  onChangeFields,
  boxChecked,
  errors,
}) => {
  return (
    <RadioButtonStyled>
      <input
        required={required}
        id={id}
        name={name}
        type="radio"
        value={value}
        onChange={onChangeFields}
        checked={boxChecked === value ? true : false}
      />
      <label htmlFor={id}>{label}</label>
    </RadioButtonStyled>
  )
}

export default RadioButton
